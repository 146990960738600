import moment from 'moment'

export const dateend = (date: Date) => {
    const hour = date.getHours().toString()
    const minutes = date.getMinutes().toString()
    return `${hour.length === 1 ? hour.padStart(2, '0') : hour}:${minutes.length === 1 ? minutes.padStart(2, '0') : minutes}`
}
export const formatDate = (listDateString: string) => {
    return moment(listDateString).format('YYYY/MM/DD hh:mm A')
}
export const formatOpenHouseDate = (startDate: string, endDateString: string) => {
    const startDateAndTime = formatDate(startDate)
    const endDate = new Date(endDateString)
    const endTime = dateend(endDate)
    return `${startDateAndTime}->${endTime}`
}
