export const ERRORS = {
    AUTH: {
        CREDENTIALS_INVALID: 'Email or password is invalid',
        EMAIL_UNVERIFIED: 'Email is not verified',
        EMAIL_ALREADY_VERIFIED: 'Email has been already verified.',
        EMAIL_LINK_INVALID_OR_EXPIRED: 'Email link expired or is invalid. Your email email confirmation link has expired.',
        EMAIL_REGISTERED_USING_GOOGLE: 'Email was registered using Google Sign In',
        EMAIL_NOT_EXIST: 'Email does not exist',
        EMAIL_IN_USE: 'Email is already in use'
    },
    TOKEN: {
        INVALID: 'Token is invalid or has expired'
    },
    UNAUTHORIZED: 'Unauthorized',
    UNAUTHORIZED_TOKEN: 'Unauthorized token',
    UNEXPECTED: 'Unexpected error ocurred',
    SOMETHING_WRONG: 'Something went wrong',
    OWNER: {
        TIME_FRAME_EMPTY: 'Please select time frame'
    }
}

export enum LISTING_TYPE {
    Sale = 'For Sale',
    Rent = 'For Rent'
}

export const PROPERTY_TYPE_INPUTS = {
    'Single Family House': { id: 'Single Family House', value: 'propertyType', name: 'propertyType', label: 'Single Family House' },
    'Condo/Coop/Townhouse/Villa': { id: 'Condo/Coop/Townhouse/Villa', value: 'propertyType', name: 'propertyType', label: 'Condo/Coop/Townhouse/Villa' },
    'Income/Multi Family Property': { id: 'Income/Multi Family Property', value: 'propertyType', name: 'propertyType', label: 'Income/Multi Family Property' },
    Land: { id: 'Land', value: 'propertyType', name: 'propertyType', label: 'Land' }
}
